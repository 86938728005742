<template>
  <div style="height:100%;width:100%;background: #F9F9F9;">
    <div class="container" style="height:45px">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">账户余额</p>
    </div>
    <div class="neirong">
      <div class="neirong-list">
        <img class="img2" src="../assets/images/account.png" alt="" />
        <strong class="mineWallet">{{ mineWallet }}</strong>
        <span class="zhye">账户余额 (元)</span>
      </div>
    </div>
    <div class="zhong">
      <span class="span1"></span>
      <strong></strong>
      <p>交易明细</p>
      <strong></strong>
      <span class="span2"></span>
    </div>
    <div class="yue-list">
      <div class="ac-list" v-for="(item, index) in Acount" :key="index">
        <div>
          <img src="../assets/images/收入.png" alt="" />
          <span>{{ item.memo }}</span>
        </div>
        <p>{{ item.money }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getAccount } from '../services/home'
export default {
  data() {
    return {
      Acount: [],
      mineWallet: ''
    }
  },
  async created() {
    const res = await getAccount()
    console.log(res)
    this.mineWallet = res.data.mineWallet
    this.Acount = res.data.data.data
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
.back {
  z-index: 100;
}
.neirong {
  position: relative;
  top: 45px;
  background: #ffffff;
  height: 170px;
  width: 100%;
  background-image: url('../assets/images/background.png');
  display: flex;
  justify-content: center;
  align-items: center;
}
.yue-list {
  margin: 10px;
  min-height: 300px;
}
.neirong-list {
  height: 115px;
  width: 172px;
}

.img3 {
  width: 100%;
  height: 160px;
  position: absolute;
  top: -10px;
}
.neirong strong {
  position: absolute;
  top: 46%;
  left: 39vw;
  font-size: 25px;
  color: white;
  z-index: 5;
}
.neirong .zhye {
  position: absolute;
  top: 62%;
  left: 39vw;
  color: white;
  z-index: 5;
}
.van-button {
  position: absolute;
  top: 54%;
  left: 37%;
  width: 117px;
}
.zhong {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: relative;
  top: 55px;
  text-align: center;
}
.zhong .span1 {
  display: block;
  height: 3px;
  width: 100px;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.55) 0%,
    #ffd24b 100%
  );
}
.zhong .span2 {
  display: block;
  height: 3px;
  width: 100px;

  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.55) 0%,
    #ffd24b 100%
  );
}
.zhong strong {
  width: 8px;
  height: 8px;
  background: #ffd24b;
  transform: rotate(45deg);
}
.zhong p {
  font-size: 20px;
  font-family: TRENDS;
  color: #333333;
  line-height: 22px;
}
.ac-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffffff;
  position: relative;
  top: 55px;
  padding: 10px 20px 0px 10px;
}
.ac-list div {
  display: flex;
  align-items: center;
  width: 31vw;
}
.ac-list div span {
  margin-left: 3vw;
}
.ac-list p {
  width: 44px;
  height: 19px;
  font-size: 16px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #5ca5ff;
  line-height: 19px;
  text-align: right;
}
</style>
